"use client";

import { getCookie, hasCookie } from "cookies-next";

import { getPricingHeaders } from "@/lib/utils/index";

interface RequestOptions {
	method?: string;
	headers?: { [key: string]: string };
	body?: string | FormData | URLSearchParams | null;
	signal?: AbortSignal;
	next?: any;
}

export async function fetchWrapper<T>(url: string, options?: RequestOptions) {
	try {
		const affiliateCookie = getCookie("affiliate")?.toString();
		const response = await fetch(process.env.NEXT_PUBLIC_API_URL + url, {
			credentials: "include",
			...options,
			headers: {
				"Content-Type": "application/json",
				"X-Requested-With": "XMLHttpRequest",
				Authorization: hasCookie("token") ? `Bearer ${getCookie("token")}` : "",
				Affiliate: affiliateCookie ? JSON.parse(affiliateCookie).code : "",
				...options?.headers,
			},
		});

		if (!response.ok) {
			// throw new Error(`HTTP error! Status: ${response.status}`);
			// console.error(`Request failed: ${response.status} ${url}`);
		}

		const data: T = url === "/sanctum/csrf-cookie" ? null : await response.json();

		return data;
	} catch (error: any) {
		throw error;
	}
}

export const swrFetcher = async ([url]: [string, string]) => {
	const headers = getPricingHeaders().headers;

	// TODO write type depending on path (url)
	const res: { data: any } = await fetchWrapper(url, {
		headers: {
			...headers,
			Authorization: hasCookie("token") ? `Bearer ${getCookie("token")}` : "",
		},
	});
	return res?.data;
};
