"use client";

import copy from "copy-to-clipboard";
import { Link } from "lucide-react";

import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";

import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

interface CopyLinkProps {
	url: string;
	className?: string;
}

const CopyLink = ({ url, className }: CopyLinkProps) => {
	return (
		<>
			<Tooltip>
				<TooltipTrigger asChild>
					<button
						onClick={(e) => {
							e.stopPropagation();
							copy(url);
							toast({
								title: "Link copied",
								variant: "success",
							});
						}}
						className={cn(
							"ml-auto hidden rounded-full border border-primary p-1.5 text-primary opacity-0 hover:bg-primary hover:text-white md:group-hover:opacity-100 xl:flex",
							className
						)}
					>
						<Link size={12} />
					</button>
				</TooltipTrigger>
				<TooltipContent variant="purple">Copy link</TooltipContent>
			</Tooltip>
		</>
	);
};

export default CopyLink;
