import { getCookie, hasCookie, setCookie } from "cookies-next";
import parse from "html-react-parser";

import { fetchWrapper } from "@/lib/hooks/fetch-client";

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

export const handleGradeInfo = (grade: any) => {
	switch (grade) {
		case "A":
			return "Multiple trials found benefit, with highly consistent evidence of moderate or greater benefit.";
		case "B":
			return `Multiple trials found benefit, but the benefit magnitude or consistency wasn't high enough to reach an A grade.`;
		case "C":
			return "Either a lone trial found benefit, or multiple trials inconsistently found benefit.";
		case "D":
			return "Trials found little to no evidence of benefit.";
		case "F":
			return "Trials found notable evidence of harm.";
	}
};

export const validateEmail = (email: string) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

// export const alphabeticallyGrouped = (data: any[]) => {
//   const sorted:any = data.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name, "es"));
//   let result:any = sorted.reduce((r: { [x: string]: { record: any[]; }; }, e: { name: string[]; }) => {
//     const numbers :any = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
//     if (numbers.includes(e.name[0].toLowerCase())) {
//       let alphabet: any = "0-9";
//       if (!r["0-9"]) r[alphabet] = { alphabet, record: [e] };
//       else r["0-9"].record.push(e);
//       return r;
//     } else {
//       let alphabet = e.name[0].toLowerCase();
//       if (!r[alphabet]) r[alphabet] = { alphabet, record: [e] };
//       else r[alphabet].record.push(e);
//       return r;
//     }
//   }, {});
//   return result;
// };

// export const getArrowScore = (arrow: string | any[]) => {
//   if (!arrow.length) {
//     return 0;
//   } else if (arrow.length > 1) {
//     return -1;
//   } else {
//     const arr = arrow[0].split("-");
//     const count = arr.pop();
//     const direction = arr.pop();
//     if (direction === "up") {
//       return count;
//     } else {
//       return -Math.abs(count + 1);
//     }
//   }
// };

// export const sortDatabase = (col: string, order: string, database: any[]) => {
//   if (order === "ASC") {
//     if (["condition", "intervention"].includes(col)) {
//       return database.sort((a: { [x: string]: { name: string; }; }, b: { [x: string]: { name: string; }; }) => {
//         const name1 = a[col].name.toLowerCase();
//         const name2 = b[col].name.toLowerCase();
//         if (name1 < name2) {
//           return -1;
//         }
//         if (name1 > name2) {
//           return 1;
//         }
//         return 0;
//       });
//     } else {
//       return database.map((item: { entries: any[]; }) => {
//         const sortedEntries = item.entries.sort((a: { [x: string]: number; arrow: { icon: any; }; }, b: { [x: string]: number; arrow: { icon: any; }; }) => {
//           if (col === "arrow") {
//             return getArrowScore(a.arrow.icon) > getArrowScore(b.arrow.icon)
//               ? 1
//               : -1;
//           } else {
//             if (typeof a[col] === "string") {
//               return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1;
//             }
//             return a[col] > b[col] ? 1 : -1;
//           }
//         });
//         return { ...item, entries: sortedEntries };
//       });
//     }
//   }
//   if (order === "DESC") {
//     if (["condition", "intervention"].includes(col)) {
//       return database.sort((a: { [x: string]: { name: string; }; }, b: { [x: string]: { name: string; }; }) => {
//         const name1 = a[col].name.toLowerCase();
//         const name2 = b[col].name.toLowerCase();
//         if (name1 > name2) {
//           return -1;
//         }
//         if (name1 < name2) {
//           return 1;
//         }
//         return 0;
//       });
//     } else {
//       return database.map((item: { entries: any[]; }) => {
//         const sortedEntries = item.entries.sort((a: { [x: string]: number; arrow: { icon: any; }; }, b: { [x: string]: number; arrow: { icon: any; }; }) => {
//           if (col === "arrow") {
//             return getArrowScore(a.arrow.icon) < getArrowScore(b.arrow.icon)
//               ? 1
//               : -1;
//           } else {
//             if (typeof a[col] === "string") {
//               return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1;
//             }

//             return a[col] < b[col] ? 1 : -1;
//           }
//         });
//         return { ...item, entries: sortedEntries };
//       });
//     }
//   }
// };

export const initialDatabseSorting = (database: any[]) => {
	const sortedConditions = database?.sort(
		(a: { total_studies: number }, b: { total_studies: number }) => {
			return b.total_studies - a.total_studies;
		}
	);
	const sortedDatabase = sortedConditions?.map((item: { entries: any[] }) => {
		const sortedEntries = item.entries?.sort(
			(
				a: { consensus: { studies: number }; samplesize: number },
				b: { consensus: { studies: number }; samplesize: number }
			) => {
				if (a.consensus.studies === b.consensus.studies) {
					return b.samplesize - a.samplesize;
				} else {
					return b.consensus.studies - a.consensus.studies;
				}
			}
		);
		return { ...item, entries: sortedEntries };
	});
	return sortedDatabase;
};

export const getRequest = async (url: string) => {
	let res;
	try {
		res = await fetchWrapper(url);
	} catch (error) {
		res = { err: JSON.stringify(error) };
	}
	return res;
};

export const canAccess = (slug: any, features: any | any[]) => {
	if (features && features.length) {
		for (let i in features) {
			if (features[i].slug === slug) {
				return true;
			}
		}
	}

	return false;
};

export const access = {
	study_summaries: "study-summaries",
	supplement_guides: "supplement-guide",
	examine_database: "examine-database",
	deep_dives_archive: "deep-dives-archive",
	examine_ai: "examine-ai",
};

export const responsiveTable = (html: string) => {
	var tableTag = /<table+/g;
	var responsiveTableTag = html.replace(tableTag, function (mdTable: string) {
		return '<div class="w-full overflow-auto">' + mdTable;
	});
	return responsiveTableTag;
};

export const options = {
	replace: ({ attribs, children }: any) => {
		if (!attribs) {
			return;
		}

		if (attribs["data-tooltip"]) {
			const variant = attribs.class === "ref-link" ? "reference" : "overview";
			// return (
			//   // <Tooltip variant={variant} render={() => <>{parse(attribs['data-tooltip'])}</>}>
			//   <span>
			//     <Link href={attribs.href}>
			//       <a className={attribs.class}>{domToReact(children: any, options: any)}</a>
			//     </Link>
			//   </span>
			//   // </Tooltip>
			// );
		}
	},
};

export const parseHTML = ({ html = "", options = false }: any) => {
	if (typeof html !== "string") {
		return;
	}
	if (!options) {
		return parse(html);
	}
	return parse(html, options);
};

export const getPricingHeaders = () => {
	let config: any = { headers: {} };
	let sp: any = getCookie("sp");
	if (sp) {
		sp = JSON.parse(sp);
		config.headers.Sp = sp.code;
	}

	let affiliate: any = getCookie("affiliate");
	if (affiliate) {
		affiliate = JSON.parse(affiliate);
		config.headers.Affiliate = affiliate.code;
	}

	return config;
};

export const token = hasCookie("token") ? getCookie("token") : null;

export const formatPageType = (pageType: string) => {
	switch (pageType) {
		case "study-summaries":
			return {
				text: "study summary",
				url: "/research-feed",
				overview: `The Examine team reviews, analyzes, and summarizes the latest health and nutrition studies to help you be healthier. Updated daily.`,
			};
		case "deep-dives":
			return {
				text: "deep dive",
				url: "/deep-dives",
				overview: `Super in-depth analyses of individual studies. Examine Deep Dives break down methodology, population characteristics, and big-picture context.`,
			};
		case "interventions":
			return {
				text: "intervention",
				url: "/interventions",
				overview: `Anything that’s tested in a health-related trial is called an intervention. Trials investigate the effect of interventions on specific health outcomes.`,
			};
		case "category":
			return {
				text: "category",
				url: "/categories",
				overview: `These 26 health categories are the top of the hierarchy of health information on Examine. Each category includes multiple health conditions and goals.`,
			};
		case "condition":
			return {
				text: "condition",
				url: "/conditions",
				overview: `Examine delves into a large number of common and uncommon health conditions and goals, along with the latest research for nonmedical interventions like supplements and diet.`,
			};
		case "goal":
			return {
				text: "goal",
				url: "/conditions",
				overview: `Examine delves into a large number of common and uncommon health conditions and goals, along with the latest research for nonmedical interventions like supplements and diet.`,
			};
		case "supplement":
			return {
				text: "supplement",
				url: "/supplements",
				overview: `Supplements are ingested substances that are not part of the diet. They can include vitamins, herbs, and other products.`,
			};
		case "food":
			return {
				text: "food",
				url: "/foods",
				overview: `What you eat has a significant effect on your health. This list includes specific ingredients as well as foods.`,
			};
		case "diet":
			return {
				text: "diet",
				url: "/diets",
				overview: `A diet is a specific pattern of eating. This can include meal timing, specific configurations of macronutrients, and a focus on including or excluding specific foods.`,
			};
		case "other":
			return {
				text: "other",
				url: "/other",
				overview: `Health research is a very broad field. Below are important interventions that don’t fit neatly into other Examine categories.`,
			};
		case "outcome":
			return {
				text: "outcome",
				url: "/outcomes",
				overview: `Outcomes are specific, measurable aspects of health. They are often the focus of randomized controlled trials.`,
			};
		case "pages":
			return { text: "page", url: null, overview: null };
		case "articles":
			return {
				text: "article",
				url: "/articles",
				overview: `Examine's analysis on current and past trending topics.`,
			};
		case "FAQs":
			return {
				text: "FAQs",
				url: "/articles",
				overview: `Examine FAQs cover specific questions about health conditions, interventions, and outcomes using easy to understand language.`,
			};
		default:
			return { text: pageType, url: null, overview: null };
	}
};
