"use client";

import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { getCookie } from "cookies-next";
import { ChevronDown } from "lucide-react";
import { useParams, usePathname } from "next/navigation";
import * as React from "react";
import { SWRConfig, unstable_serialize } from "swr";

import FeedbackFishWidget from "@/components/feedback-fish";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import ClickOutside from "@/lib/click-outside";
import { cn } from "@/lib/utils";

export interface State {
	displayModal?: boolean;
	displaySidebar?: boolean;
	displaySearchDialog?: boolean;
	modalView?: React.ReactElement | null;
	displayMenu?: boolean;
	displayExamineAIMenu?: boolean;
	menuView?: React.ReactElement;
	pageTitle?: string;
}

const initialState = {
	displayModal: false,
	displayMenu: false,
	displayExamineAIMenu: true,
	displaySearchDialog: false,
	modalView: null,
	menuView: null,
	pageTitle: "",
};

type Action =
	| {
			type: "OPEN_MODAL";
	  }
	| {
			type: "CLOSE_MODAL";
	  }
	| {
			type: "SET_MODAL_VIEW";
			view: React.ReactElement;
	  }
	| {
			type: "SET_MENU_VIEW";
			view: React.ReactElement;
	  }
	| {
			type: "SET_PAGE_TITLE";
			title: string;
	  }
	| {
			type: "OPEN_MENU";
	  }
	| {
			type: "CLOSE_MENU";
	  }
	| {
			type: "OPEN_SEARCH_DIALOG";
	  }
	| {
			type: "CLOSE_SEARCH_DIALOG";
	  }
	| {
			type: "TOGGLE_GPT_MENU";
	  };

export const UIContext = React.createContext<State | any>(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state: State, action: Action): State {
	switch (action.type) {
		case "OPEN_MODAL": {
			return {
				...state,
				displayModal: true,
				displaySidebar: false,
			};
		}
		case "CLOSE_MODAL": {
			return {
				...state,
				displayModal: false,
			};
		}
		case "SET_MODAL_VIEW": {
			return {
				...state,
				modalView: action.view,
			};
		}
		case "SET_MENU_VIEW": {
			return {
				...state,
				menuView: action.view,
			};
		}
		case "SET_PAGE_TITLE": {
			return {
				...state,
				pageTitle: action.title,
			};
		}
		case "OPEN_MENU": {
			return {
				...state,
				displayMenu: true,
			};
		}
		case "CLOSE_MENU": {
			return {
				...state,
				displayMenu: false,
			};
		}
		case "OPEN_SEARCH_DIALOG": {
			return {
				...state,
				displaySearchDialog: true,
			};
		}
		case "CLOSE_SEARCH_DIALOG": {
			return {
				...state,
				displaySearchDialog: false,
			};
		}
		case "TOGGLE_GPT_MENU": {
			return {
				...state,
				displayExamineAIMenu: !state.displayExamineAIMenu,
			};
		}
		default: {
			return state;
		}
	}
}

export const UIProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
	const [state, dispatch] = React.useReducer(uiReducer, initialState);

	const openModal = React.useCallback(() => dispatch({ type: "OPEN_MODAL" }), [dispatch]);
	const closeModal = React.useCallback(() => dispatch({ type: "CLOSE_MODAL" }), [dispatch]);

	const openMenu = React.useCallback(() => dispatch({ type: "OPEN_MENU" }), [dispatch]);
	const closeMenu = React.useCallback(() => dispatch({ type: "CLOSE_MENU" }), [dispatch]);

	const setModalView = React.useCallback(
		(view: React.ReactElement) => dispatch({ type: "SET_MODAL_VIEW", view }),
		[dispatch]
	);

	const setMenuView = React.useCallback(
		(view: React.ReactElement) => dispatch({ type: "SET_MENU_VIEW", view }),
		[dispatch]
	);

	const setPageTitle = React.useCallback(
		(title: string) => dispatch({ type: "SET_PAGE_TITLE", title }),
		[dispatch]
	);

	const openSearchDialog = React.useCallback(
		() => dispatch({ type: "OPEN_SEARCH_DIALOG" }),
		[dispatch]
	);
	const closeSearchDialog = React.useCallback(
		() => dispatch({ type: "CLOSE_SEARCH_DIALOG" }),
		[dispatch]
	);

	const toogleExamineAIMenu = React.useCallback(() => {
		dispatch({ type: "TOGGLE_GPT_MENU" });
	}, [dispatch]);

	const value = React.useMemo(
		() => ({
			...state,
			openModal,
			closeModal,
			openMenu,
			toogleExamineAIMenu,
			closeMenu,
			setModalView,
			setMenuView,
			setPageTitle,
			openSearchDialog,
			closeSearchDialog,
		}),
		[
			closeMenu,
			closeModal,
			closeSearchDialog,
			openMenu,
			toogleExamineAIMenu,
			openModal,
			openSearchDialog,
			setMenuView,
			setModalView,
			setPageTitle,
			state,
		]
	);

	return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
	const context = React.useContext(UIContext);
	if (context === undefined) {
		throw new Error(`useUI must be used within a UIProvider`);
	}

	return context;
};

export const ManagedUIContext: React.FC<{
	fallbackData;
	children?: React.ReactNode;
}> = ({ fallbackData, children }) => {
	const token = getCookie("token");
	const { user, subscription, features } = fallbackData;

	return (
		<UIProvider>
			<SWRConfig
				value={{
					revalidateOnFocus: false,
					fallback: {
						[unstable_serialize(["/v1/user", token])]: user,
						[unstable_serialize(["/v1/subscription/info", token])]: subscription,
						[unstable_serialize(["/v1/subscription/features", token])]: features,
					},
				}}
			>
				{children}
			</SWRConfig>
		</UIProvider>
	);
};

export const ModalUI = () => {
	const { displayModal, closeModal, modalView } = useUI();
	const ref = React.useRef(null);

	const handleKey = React.useCallback(
		(e: KeyboardEvent) => {
			if (e.key === "Escape") {
				return closeModal();
			}
		},
		[closeModal]
	);

	React.useEffect(() => {
		const modal = ref.current;

		if (modal) {
			disableBodyScroll(modal, { reserveScrollBarGap: true });
			window.addEventListener("keydown", handleKey);
		}
		return () => {
			clearAllBodyScrollLocks();
			window.removeEventListener("keydown", handleKey);
		};
	}, [handleKey]);

	if (displayModal) {
		return (
			<div className="fixed inset-0 z-[1001] mx-auto flex justify-center overflow-auto bg-black/40 backdrop-blur-[0.4px]">
				{/* TODO: @therealmarzouq: build bug */}
				{/* @ts-ignore  */}
				<ClickOutside active onClick={closeModal}>
					<div
						className="relative w-screen max-w-max items-center overflow-auto sm:flex sm:max-h-screen"
						role="dialog"
						ref={ref}
					>
						{modalView}
					</div>
				</ClickOutside>
			</div>
		);
	}

	return null;
};

type MenuUIProps = {};

export const MenuUI = ({}: MenuUIProps) => {
	const { setMenuView, menuView, setPageTitle, pageTitle } = useUI();
	const pathname = usePathname();
	const params = useParams();

	React.useEffect(() => {
		const routes = [
			"/conditions/[slug]/",
			"/outcomes/[slug]/",
			"/categories/[slug]/",
			"/supplements/[slug]/",
			"/supplements/[slug]/research/",
			"/diets/[slug]/",
			"/foods/[slug]/",
			"/other/[slug]/",
			"/guides/[slug]/",
			"/research-feed/",
			"/research-feed/filter/",
			"/members/dashboard/",
			"/members/enterprise-users/",
			"/members/saved/",
			"/members/membership/",
			"/members/receipts/",
			"/members/profile/",
			"/members/myreferrals/",
		];
		const isSupplementPage = pathname.startsWith('/supplements/')

		if (
			!routes.includes(pathname) &&
			!routes.includes(pathname.replace(String(params?.slug), "[slug]")) &&
			!isSupplementPage
		) {
			setMenuView(null);
			setPageTitle("");
		}
	}, [pathname, params, setMenuView, setPageTitle]);

	return (
		<div className="block border-t border-gray-300 px-5 py-6">
			{menuView && (
				<Accordion type="single" id="menu" defaultValue="menu" collapsible>
					<AccordionItem value="menu" className="mt-0">
						<AccordionTrigger
							as="h2"
							className="group flex cursor-pointer items-center pb-2 md:text-30 3xl:text-40 [&[data-state=open]>span>span.chevron]:rotate-180"
							hideChevron
						>
							<span className="font-lora text-22 font-medium text-primary">{pageTitle}</span>
							<span className="chevron ml-auto grid h-7 w-7 place-content-center rounded-full bg-examine-purple-100 transition-transform duration-500">
								<ChevronDown className={cn("h-5 w-5 transition-transform duration-500")} />
							</span>
						</AccordionTrigger>

						<AccordionContent>{menuView}</AccordionContent>
					</AccordionItem>
				</Accordion>
			)}
			<div className="">
				<FeedbackFishWidget>Send feedback</FeedbackFishWidget>
			</div>
		</div>
	);
};
